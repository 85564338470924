<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >
    <!-- Content Left -->
    <b-col
      class="content-header-left mb-2"
      cols="12"
      md="9"
    >
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2 class="content-header-title float-left pr-1 mb-0">
            {{ $t($route.meta.pageTitle) }}
            <b-icon
              id="boomark-add-remove"
              icon="star-fill"
              size="16"
              style="width: 20px; height: 20px; position: relative; top: -2px; cursor: pointer"
              class="text-warning align-text-middle hide"
            />
            <b-tooltip
              triggers="hover"
              target="boomark-add-remove"
              :title="$t('Add/Remove bookmark')"
              :delay="{ show: 1000, hide: 50 }"
            />
          </h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="(item, index) in $route.meta.breadcrumb"
                :key="`${item.text}-${index}`"
                :active="item.active"
                :to="item.to"
              >
                {{ $t(item.text) }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>

    <!-- Content Right -->
    <b-col
      class="content-header-right text-md-right d-md-block d-none mb-1"
      md="3"
      cols="12"
    >
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="flat-primary"
        @click="$router.go(-1)"
      >
        <feather-icon
          icon="ArrowLeftIcon"
          class="mr-50"
        />
        <span class="align-middle">{{ $t('Voltar') }}</span>
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import { BBreadcrumb, BBreadcrumbItem, BRow, BCol, BButton, BTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BButton,
    BTooltip,
  },
}
</script>
