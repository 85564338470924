var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout-vertical",
    {
      scopedSlots: _vm._u([
        {
          key: "navbar",
          fn: function (ref) {
            var toggleVerticalMenuActive = ref.toggleVerticalMenuActive
            return [
              _c("navbar", {
                attrs: {
                  "toggle-vertical-menu-active": toggleVerticalMenuActive,
                },
              }),
            ]
          },
        },
      ]),
    },
    [
      _c("router-view", { key: _vm.$route.fullPath }),
      _c(
        "div",
        { attrs: { slot: "breadcrumb" }, slot: "breadcrumb" },
        [_c("app-breadcrumb")],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [_c("app-footer")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }