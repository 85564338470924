var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$route.meta.breadcrumb || _vm.$route.meta.pageTitle
    ? _c(
        "b-row",
        { staticClass: "content-header" },
        [
          _c(
            "b-col",
            {
              staticClass: "content-header-left mb-2",
              attrs: { cols: "12", md: "9" },
            },
            [
              _c(
                "b-row",
                { staticClass: "breadcrumbs-top" },
                [
                  _c("b-col", { attrs: { cols: "12" } }, [
                    _c(
                      "h2",
                      {
                        staticClass:
                          "content-header-title float-left pr-1 mb-0",
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t(_vm.$route.meta.pageTitle)) + " "
                        ),
                        _c("b-icon", {
                          staticClass: "text-warning align-text-middle hide",
                          staticStyle: {
                            width: "20px",
                            height: "20px",
                            position: "relative",
                            top: "-2px",
                            cursor: "pointer",
                          },
                          attrs: {
                            id: "boomark-add-remove",
                            icon: "star-fill",
                            size: "16",
                          },
                        }),
                        _c("b-tooltip", {
                          attrs: {
                            triggers: "hover",
                            target: "boomark-add-remove",
                            title: _vm.$t("Add/Remove bookmark"),
                            delay: { show: 1000, hide: 50 },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "breadcrumb-wrapper" },
                      [
                        _c(
                          "b-breadcrumb",
                          [
                            _c(
                              "b-breadcrumb-item",
                              { attrs: { to: "/" } },
                              [
                                _c("feather-icon", {
                                  staticClass: "align-text-top",
                                  attrs: { icon: "HomeIcon", size: "16" },
                                }),
                              ],
                              1
                            ),
                            _vm._l(
                              _vm.$route.meta.breadcrumb,
                              function (item, index) {
                                return _c(
                                  "b-breadcrumb-item",
                                  {
                                    key: item.text + "-" + index,
                                    attrs: { active: item.active, to: item.to },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t(item.text)) + " "
                                    ),
                                  ]
                                )
                              }
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            {
              staticClass:
                "content-header-right text-md-right d-md-block d-none mb-1",
              attrs: { md: "3", cols: "12" },
            },
            [
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple.400",
                      value: "rgba(113, 102, 240, 0.15)",
                      expression: "'rgba(113, 102, 240, 0.15)'",
                      modifiers: { 400: true },
                    },
                  ],
                  attrs: { variant: "flat-primary" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.go(-1)
                    },
                  },
                },
                [
                  _c("feather-icon", {
                    staticClass: "mr-50",
                    attrs: { icon: "ArrowLeftIcon" },
                  }),
                  _c("span", { staticClass: "align-middle" }, [
                    _vm._v(_vm._s(_vm.$t("Voltar"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }